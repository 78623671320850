import { DoneAll as DoneAllIcon, InfoOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  InputLabel,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Modal,
  TextField,
  Tooltip,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useTheme } from '@mui/system';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { fetchGroupMembers, postTaskGroup, updateTaskGroup } from '../../../../Utilities/ForemanApiClient';
import { API_ROOT } from '../../../../api-config';
import { ActionType, stringAvatar, stringToColor } from '../../../Pages/dacq/taskGroupUtils';
import { styles } from '../../../Pages/job-groups/jobGroupStyles';
import { showSnackbar } from '../../../Utils/showSnackBar';
import { useFetch } from '../../../hooks/useFetch';
import { FieldWrapper, modalStyle } from './addTaskGroupModalStyles';

export function AddTaskGroupModal({ onTaskGroupAdd, argsActionType, taskGroup, handleClose, open }) {
  const [actionType, setActionType] = useState(ActionType.ADD);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [minimumRequiredMembers, setMinimumRequiredMembers] = useState('');
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [selectedJobGroup, setSelectedJobGroup] = useState({});
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [nameError, setNameError] = useState(false);
  const [minimumRequiredMembersError, setMinimumRequiredMembersError] = useState(false);
  const [membersListError, setMembersListError] = useState(false);
  const [isGroupMembersLoading, setIsGroupMembersLoading] = useState(false);
  const [jobGroups, setJobGroups] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [members, setMembers] = useState([]);
  const [isBtnEnabled, setIsBtnEnabled] = useState(false);
  const theme = useTheme();

  const { data, isLoading, error } = useFetch({
    url: `${API_ROOT}/TaskGroupAssignment/GetManualUploadsHarvesterJobGroups`,
    method: 'GET',
  });
  useEffect(() => {
    if (data) {
      const jobGroup = data.map((record) => ({
        id: record.id,
        name: record.name,
        description: record.description,
        jobTemplateIds: record.jobTemplateIds,
      }));
      setJobGroups(jobGroup);
    }
  }, [data]);

  const retrieveGroupMembers = async () => {
    try {
      setIsGroupMembersLoading(true);
      const groupMembers = await fetchGroupMembers({
        memberEmails: [],
      });

      setMembers(groupMembers);
    } catch (error) {
      showSnackbar('error', `Something went wrong while fetching dacq group members: ${error.message}`);
    } finally {
      setIsGroupMembersLoading(false);
    }
  };

  const handleAddTaskGroup = async () => {
    setIsGroupMembersLoading(true);

    try {
      const taskGroupPayload = {
        description,
        isActive,
        name,
        memberEmails: selectedMembers.map((user) => user.email),
        minimumRequiredMembers: parseInt(minimumRequiredMembers),
        jobGroupId: selectedJobGroup.id,
        startDate: startDate ? startDate.toISOString() : null,
        endDate: endDate ? endDate.toISOString() : null,
        editedAt: null,
      };

      const taskGroupResponse = await postTaskGroup(taskGroupPayload);
      const addedTaskGroup = {
        ...taskGroupResponse,
        createdAt: taskGroupResponse.createdAt,
      };

      onTaskGroupAdd(addedTaskGroup);
      resetAll();
      handleClose();
      showSnackbar('success', 'Task Group added successfully');
    } catch (error) {
      showSnackbar('error', `Something went wrong while adding task group: ${error.message}`);
      console.error('Error is:', error.message);
    } finally {
      setIsGroupMembersLoading(false);
    }
  };

  const handleEditTaskGroup = async () => {
    setIsGroupMembersLoading(true);
    const taskGroupPayload = {
      id: taskGroup.id,
      description,
      isActive,
      name,
      memberEmails: selectedMembers.map((member) => member.email),
      minimumRequiredMembers: parseInt(minimumRequiredMembers),
      startDate: startDate ? startDate.toISOString() : null,
      endDate: endDate ? endDate.toISOString() : null,
      jobGroupId: selectedJobGroup.id,
    };

    try {
      await updateTaskGroup(taskGroupPayload);
      onTaskGroupAdd(taskGroupPayload);
      resetAll();
      handleClose();
      showSnackbar('success', 'Task Group updated successfully');
    } catch (error) {
      showSnackbar('error', `Something went wrong while updating task group: ${error.message}`);
      console.error('Error:', error.message);
    } finally {
      setIsGroupMembersLoading(false);
    }
  };

  useEffect(() => {
    setActionType(argsActionType);

    if (open && taskGroup) {
      const commonMembers = members.filter((member) => taskGroup.memberEmails.includes(member.email));
      setName(taskGroup.name.trim() || '');
      setMinimumRequiredMembers(taskGroup.minimumRequiredMembers || '');
      setSelectedMembers(commonMembers || []);
      setDescription(taskGroup.description.trim() || '');
      setIsActive(taskGroup.isActive);
      setStartDate(taskGroup.startDate ? dayjs(taskGroup.startDate).startOf('day') : null);
      setEndDate(taskGroup.endDate ? dayjs(taskGroup.endDate).startOf('day') : null);
      const selectedJobGroup = jobGroups.find((jobGroup) => jobGroup.id === taskGroup.jobGroupId) || {};
      setSelectedJobGroup(selectedJobGroup);
    }
  }, [open, taskGroup]);

  useEffect(() => {
    retrieveGroupMembers();
  }, []);

  const resetAll = () => {
    setName('');
    setMinimumRequiredMembers('');
    setDescription('');
    setSelectedMembers([]);
    setSelectedJobGroup({});
    setNameError(false);
    setMinimumRequiredMembersError(false);
    setMembersListError(false);
    setStartDate(null);
    setEndDate(null);
  };

  useEffect(() => {
    setIsBtnEnabled(
      name.trim() && minimumRequiredMembers.toString().trim() && selectedJobGroup.name && selectedMembers.length > 0
    );
  }, [name, minimumRequiredMembers, selectedMembers, selectedJobGroup]);

  const handleConfirm = async () => {
    setNameError(false);
    setMinimumRequiredMembersError(false);
    setMembersListError(false);
    let isValid = true;

    if (!name.trim()) {
      setNameError(true);
      isValid = false;
    }

    if (!minimumRequiredMembers.toString().trim()) {
      setMinimumRequiredMembersError(true);
      isValid = false;
    }

    if (selectedMembers.length === 0) {
      setMembersListError(true);
      isValid = false;
    }

    if (isValid) {
      if (actionType === ActionType.ADD) {
        handleAddTaskGroup();
      }
      if (actionType === ActionType.EDIT || actionType === ActionType.ASSIGNMENTEDIT) {
        handleEditTaskGroup();
      }
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Modal
        open={open}
        onClose={() => {
          resetAll();
          handleClose();
        }}
        aria-labelledby="modal-modal-name"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Container
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1.5rem',
              overflowY: 'auto',
              borderRadius: '1rem',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h4 style={{ marginRight: 'auto', marginTop: '0px' }}>Manage Task Group</h4>
              <div style={{ marginLeft: 'auto' }}>
                {actionType === ActionType.ADD ? null : (
                  <Button
                    variant={actionType === ActionType.VIEW ? 'contained' : 'outlined'}
                    onClick={() => {
                      if (actionType === ActionType.VIEW) {
                        setActionType(ActionType.EDIT);
                      } else {
                        setActionType(ActionType.VIEW);
                      }
                    }}
                  >
                    {actionType === ActionType.EDIT ? 'View Only' : 'Edit'}
                  </Button>
                )}
              </div>
            </div>

            <FieldWrapper>
              <InputLabel
                sx={{
                  '& .MuiFormLabel-asterisk': { color: theme.palette.taskGroupColors.red },
                }}
                required={true}
                id="demo-simple-select-label"
              >
                Name
              </InputLabel>
              <TextField
                InputProps={{
                  readOnly: actionType === ActionType.VIEW || actionType === ActionType.ASSIGNMENTEDIT,
                }}
                placeholder="Enter name.."
                error={nameError}
                helperText={nameError ? 'Name is required' : ''}
                variant="outlined"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </FieldWrapper>

            <FieldWrapper>
              <InputLabel
                sx={{
                  '& .MuiFormLabel-asterisk': { color: theme.palette.taskGroupColors.red },
                }}
                required={true}
                id="demo-simple-select-label"
              >
                Description
              </InputLabel>
              <TextField
                placeholder="Enter description.."
                InputProps={{
                  readOnly: actionType === ActionType.VIEW || actionType === ActionType.ASSIGNMENTEDIT,
                }}
                error={nameError}
                variant="outlined"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
            </FieldWrapper>

            <div style={{ display: 'flex', gap: '1rem' }}>
              <FieldWrapper>
                <InputLabel
                  required={true}
                  sx={{
                    '& .MuiFormLabel-asterisk': { color: theme.palette.taskGroupColors.red },
                  }}
                  id="demo-simple-select-label"
                >
                  Job Groups
                </InputLabel>
                <Autocomplete
                  fullWidth={true}
                  readOnly={actionType === ActionType.VIEW}
                  id="checkboxes-tags-demo"
                  disableCloseOnSelect
                  defaultValue={selectedJobGroup.name !== undefined ? selectedJobGroup : null}
                  options={jobGroups}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      setSelectedJobGroup(newValue);
                    } else {
                      setSelectedJobGroup({});
                    }
                  }}
                  value={selectedJobGroup.name !== undefined ? selectedJobGroup : null}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option, { selected }) => (
                    <ListItem
                      {...props}
                      disablePadding
                      secondaryAction={
                        <Tooltip
                          title={
                            <h6
                              style={{
                                fontSize: '1rem',
                                textTransform: 'none',

                                fontWeight: '400',
                              }}
                            >
                              {option.description}
                            </h6>
                          }
                        >
                          <InfoOutlined />
                        </Tooltip>
                      }
                    >
                      <ListItemButton
                        disableRipple
                        disableTouchRipple
                        disableFocusRibble
                        sx={{ '&:hover': { backgroundColor: 'transparent' } }}
                        dense
                      >
                        <ListItemText primaryTypographyProps={{ fontSize: '18px' }} primary={option.name} />
                      </ListItemButton>
                    </ListItem>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={membersListError}
                      helperText={membersListError ? 'Members are required' : ''}
                      placeholder={actionType === ActionType.VIEW ? '' : 'Select job group..'}
                    />
                  )}
                />
              </FieldWrapper>
              <FieldWrapper>
                <InputLabel id="demo-simple-select-label">Minimum required members</InputLabel>
                <TextField
                  placeholder="Enter Minimum required members.."
                  inputProps={{
                    min: 0,
                    maxLength: 5,
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                    readOnly: actionType === ActionType.VIEW || actionType === ActionType.ASSIGNMENTEDIT,
                  }}
                  type="number"
                  error={minimumRequiredMembersError}
                  helperText={minimumRequiredMembersError ? 'Minimum  Members is required' : ''}
                  variant="outlined"
                  value={minimumRequiredMembers}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (!value.includes('e') && value !== '0' && !value.includes('.') && /^$|^\d+$/.test(value)) {
                      setMinimumRequiredMembers(value);
                    }
                  }}
                />
              </FieldWrapper>
            </div>
            <FieldWrapper>
              <InputLabel
                required={true}
                sx={{
                  '& .MuiFormLabel-asterisk': { color: theme.palette.taskGroupColors.red },
                }}
                id="demo-simple-select-label"
              >
                Default members
              </InputLabel>
              <Autocomplete
                fullWidth={true}
                readOnly={actionType === ActionType.VIEW}
                multiple
                id="checkboxes-tags-demo"
                disableCloseOnSelect
                options={members}
                onChange={(event, newValue) => {
                  setSelectedMembers(newValue);
                }}
                value={selectedMembers}
                getOptionLabel={(option) => option.displayName}
                renderOption={(props, option, { selected }) => (
                  <ListItem
                    {...props}
                    secondaryAction={
                      <Avatar sx={{ backgroundColor: stringToColor(option.displayName) }} {...stringAvatar(option.displayName)} />
                    }
                    disablePadding
                  >
                    <ListItemButton disableRipple disableTouchRipple disableFocusRibble dense>
                      <ListItemIcon>
                        <Checkbox edge="start" checked={selected} tabIndex={-1} disableRipple />
                      </ListItemIcon>
                      <ListItemText primaryTypographyProps={{ fontSize: '18px' }} primary={option.displayName} />
                    </ListItemButton>
                  </ListItem>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={membersListError}
                    helperText={membersListError ? 'Members are required' : ''}
                    placeholder={actionType === ActionType.VIEW ? '' : 'Select default members..'}
                  />
                )}
              />
            </FieldWrapper>

            <div style={{ display: 'flex', gap: '1rem' }}>
              <FieldWrapper>
                <InputLabel id="demo-simple-select-label">Start Date</InputLabel>
                <DatePicker
                  value={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                  }}
                  readOnly={actionType === ActionType.VIEW || actionType === ActionType.ASSIGNMENTEDIT}
                  sx={{ width: '100%' }}
                  slotProps={{
                    field: { clearable: true },
                  }}
                />
              </FieldWrapper>
              <FieldWrapper>
                <InputLabel id="demo-simple-select-label">End Date</InputLabel>
                <DatePicker
                  onChange={(date) => {
                    setEndDate(date);
                  }}
                  value={endDate}
                  readOnly={actionType === ActionType.VIEW || actionType === ActionType.ASSIGNMENTEDIT}
                  sx={{ width: '100%' }}
                  slotProps={{
                    field: { clearable: true },
                  }}
                />
              </FieldWrapper>
            </div>

            <FormGroup aria-label="position" row>
              <FormControlLabel
                sx={{ margin: '0px' }}
                label="Is active"
                labelPlacement="start"
                control={
                  <Checkbox
                    sx={{ margin: '0px' }}
                    disabled={actionType === ActionType.VIEW}
                    checked={isActive}
                    onChange={(e) => {
                      setIsActive(e.target.checked);
                    }}
                  />
                }
              />
            </FormGroup>
          </Container>
          <div style={{ position: 'absolute', bottom: '1rem', right: '1.5rem' }}>
            <Tooltip
              componentsProps={{
                tooltip: {
                  sx: {
                    fontSize: '1rem',
                  },
                },
                title: { sx: { color: 'red' } },
              }}
              placement="top-end"
              title={isBtnEnabled ? '' : 'Please fill all required fields'}
            >
              <span>
                <LoadingButton
                  variant="contained"
                  sx={{ ...styles.CreateButton }}
                  startIcon={<DoneAllIcon />}
                  loading={isGroupMembersLoading}
                  disabled={isGroupMembersLoading || !isBtnEnabled || actionType === ActionType.VIEW}
                  onClick={handleConfirm}
                >
                  Confirm
                </LoadingButton>
              </span>
            </Tooltip>
          </div>
        </Box>
      </Modal>
    </LocalizationProvider>
  );
}

export default AddTaskGroupModal;
