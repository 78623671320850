import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router';
import routesV2 from './routesV2';
import useAuth from './useAuthContext';
import Page from './V2/Pages/main-page/Page';
import PageNotFound from './V2/Pages/page-not-found/pageNotFound';

import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { CssBaseline, Switch, useMediaQuery } from '@mui/material';
import customTheme, { ColorModeContext, getDesignTokens } from './V2/Theme/theme';
import Loader from './V2/Components/Loader/Loader';

function AppV2() {
  const { isAuthenticated, isAuthorized } = useAuth();
  const location = useLocation();
  const [mode, setMode] = React.useState(() => {
    const savedMode = localStorage.getItem('mode');
    if (savedMode) {
      return savedMode;
    } else {
      // Otherwise, set the initial mode to light
      return 'light';
    }
  });

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    []
  );

  useEffect(() => {
    // Save the mode to localStorage whenever it changes
    localStorage.setItem('mode', mode);
  }, [mode]);

  const getRoutes = (routesV2) => {
    return routesV2.map((prop, key) => {
      return <Route path={prop.path} element={<prop.component />} key={key} />;
    });
  };
  const findCurrentRoute = () => routesV2.find(route => location.pathname.replace("/V2", "").startsWith(route.path));
  
  const currentRoute = findCurrentRoute();

  const designTokenPalette = getDesignTokens(mode).palette;

  const darkModeTheme = createTheme({
    palette: designTokenPalette,
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: '1rem',
          },
        },
      },
    },
  });

  return (
    <>
      {isAuthenticated && (
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={darkModeTheme}>
            <CssBaseline />
            <Page title={currentRoute?.name} style={{ border: 2 }}>
              {isAuthorized ? (
                <Routes>
                  {getRoutes(routesV2)}
                  <Route path="/" element={<Navigate to="/V2/clientmanager" />} />
                  <Route path="*" element={<PageNotFound />} />
                </Routes>
              ) : (
                <Loader />
              )}
            </Page>
          </ThemeProvider>
        </ColorModeContext.Provider>
      )}
    </>
  );
}

export default AppV2;
