import React from 'react';
import { Table, TableBody, Box, Paper, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { TableSkeleton } from '../../Pages/dacq/taskGroupUtils';
import NoDataFound from '../../Assets/NoDataFound';

export function TaskGroupTableBase({ columns, tableBody, isLoading, fullWidth }) {
  return (
    <Box
      sx={{
        display: 'flex',
        width: fullWidth ? '100%' : '80%',
        flexDirection: 'column',
        alignItems: 'start',
        gap: '1.5rem',
      }}
    >
      <TableContainer component={Paper} sx={{ borderRadius: '0.5rem' }}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((cell, index) => (
                <TableCell
                  sx={{
                    fontWeight: '600',
                    fontSize: '1rem',
                    textTransform: 'uppercase',
                  }}
                  key={index}
                >
                  {cell}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableSkeleton ColumnCount={columns.length} />
            ) : tableBody.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  <NoDataFound />
                </TableCell>
              </TableRow>
            ) : (
              tableBody.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {Object.values(row).map((value, cellIndex) => (
                    <TableCell key={cellIndex}>{value}</TableCell>
                  ))}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
