import { showSnackbar } from '../V2/Utils/showSnackBar';
import { API_ROOT, LANTERN_API_ROOT } from '../api-config';
import { saveFreshAccessTokenInLocalStorage } from './msalUtilities';

const getAccessToken = async () => {
  await saveFreshAccessTokenInLocalStorage();
  return localStorage.getItem('auth');
};

const executePostRequest = async (url, entity) => {
  const accessToken = await getAccessToken();

  if (accessToken) {
    const headers = new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    });

    return fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(entity),
      mode: 'cors',
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }

        return response.json();
      })
      .catch((error) => {
        console.error(error); // eslint-disable-line no-console
      });
  }

  return null;
};

const executeGetRequest = async (url, sort = '', returnAsJson = true) => {
  const accessToken = await getAccessToken();

  const getUrl = sort ? `${url}&sort=${sort}` : url;

  if (accessToken) {
    const headers = new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    });

    return fetch(getUrl, {
      method: 'GET',
      headers,
      mode: 'cors',
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }

        return returnAsJson ? response.json() : response.text();
      })
      .catch((error) => {
        console.error(error); // eslint-disable-line no-console
      });
  }

  return null;
};

const executeAnonymousGetRequest = async (url, sort = '') => {
  const getUrl = sort ? `${url}&sort=${sort}` : url;

  const headers = new Headers({
    Accept: 'application/json',
    'Content-Type': 'application/json',
  });

  return fetch(getUrl, {
    method: 'GET',
    headers,
    mode: 'cors',
  })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .catch((error) => {
      console.error(error); // eslint-disable-line no-console
      return null;
    });
};

const executePutRequest = async (url, entity) => {
  const accessToken = await getAccessToken();

  if (accessToken) {
    const headers = new Headers({
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json-patch+json',
    });

    return fetch(url, {
      method: 'PUT',
      headers,
      body: entity === null ? null : JSON.stringify(entity),
      mode: 'cors',
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return true;
      })
      .catch((error) => {
        console.error(error); // eslint-disable-line no-console
      });
  }

  return false;
};

const executeAnonymousPutRequest = async (url, entity) => {
  const headers = new Headers({
    Accept: 'application/json',
    'Content-Type': 'application/json-patch+json',
  });

  return fetch(url, {
    method: 'PUT',
    headers,
    body: entity === null ? null : JSON.stringify(entity),
    mode: 'cors',
  })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .catch((error) => {
      console.error(error); // eslint-disable-line no-console
      return null;
    });
};

const executeDeleteRequest = async (url, ids) => {
  const accessToken = await getAccessToken();

  if (accessToken) {
    const headers = new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    });

    const payload = ids;

    return fetch(url, {
      method: 'DELETE',
      headers,
      mode: 'cors',
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return true;
      })
      .catch((error) => {
        console.error(error); // eslint-disable-line no-console
        return false;
      });
  }

  return false;
};

const executePatchRequest = async (url, path, value) => {
  const accessToken = await getAccessToken();

  if (accessToken) {
    const headers = new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json-patch+json',
      Authorization: `Bearer ${accessToken}`,
    });
    const payload = [
      {
        value,
        path,
        op: 'replace',
      },
    ];

    return fetch(url, {
      method: 'PATCH',
      headers,
      mode: 'cors',
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return true;
      })
      .catch((error) => {
        console.error(error); // eslint-disable-line no-console
      });
  }

  return false;
};

const executeHealthCheckRequest = async (url) => {
  return await fetch(url, {
    method: 'GET',
  })
    .then((response) => {
      if (!response.ok) {
        return false;
      }
      return true;
    })
    .catch((error) => {
      console.error(error); // eslint-disable-line no-console
    });
};

const executeAnonymousRetailLinkBotPutRequest = async (url, entity) => {
  const headers = new Headers({
    Accept: 'application/json',
    'Content-Type': 'application/json-patch+json',
  });

  return fetch(url, {
    method: 'PUT',
    headers,
    body: entity === null ? null : JSON.stringify(entity),
    mode: 'cors',
  })
    .then((response) => {
      if (!response.ok) {
        return response.text().then((errorMessage) => {
          throw new Error(errorMessage);
        });
      }
      return response;
    })
    .catch((error) => {
      console.log(error.message); // eslint-disable-line no-console
      return error;
    });
};

export const createParentClient = async (parentClient) => {
  const url = `${API_ROOT}/parentclients`;

  return executePostRequest(url, parentClient);
};

export const updateParentClient = async (parentClient) => {
  const url = `${API_ROOT}/parentclients/${parentClient.id}`;

  return executePutRequest(url, parentClient);
};

export const getParentClients = async () => {
  const url = `${API_ROOT}/parentclients?limit=0`;
  const parentClients = await executeGetRequest(url, 'Name');

  return parentClients ? parentClients.records : [];
};

export const patchParentClient = async (parentClientId, path, value) => {
  const url = `${API_ROOT}/parentclients/${parentClientId}`;

  return executePatchRequest(url, path, value);
};

export const deleteParentClient = async (parentClientId) => {
  const url = `${API_ROOT}/parentclients/${parentClientId}`;

  return executeDeleteRequest(url);
};

export const getChildClients = async () => {
  const url = `${API_ROOT}/childclients?limit=0`;
  const childClients = await executeGetRequest(url, 'Name');

  return childClients ? childClients.records : [];
};

export const getChildClientsByParentId = async (parentId) => {
  const url = `${API_ROOT}/childclients?limit=0&parentClientId=${parentId}`;
  const childClients = await executeGetRequest(url, 'Name');

  return childClients ? childClients.records : [];
};

export const createChildClient = async (childClient) => {
  const url = `${API_ROOT}/childclients`;

  return executePostRequest(url, childClient);
};

export const updateChildClient = async (childClient) => {
  const url = `${API_ROOT}/childclients/${childClient.id}`;

  return executePutRequest(url, childClient);
};

export const deleteChildClient = async (childClientId) => {
  const url = `${API_ROOT}/childclients/${childClientId}`;

  return executeDeleteRequest(url);
};

export const patchChildClient = async (childClientId, path, value) => {
  const url = `${API_ROOT}/childclients/${childClientId}`;

  return executePatchRequest(url, path, value);
};

export const createRetailer = async (retailer) => {
  const url = `${API_ROOT}/retailers`;

  return executePostRequest(url, retailer);
};

export const getRetailers = async () => {
  const url = `${API_ROOT}/retailers?limit=0`;
  const retailers = await executeGetRequest(url, 'Name');

  return retailers ? retailers.records : [];
};

export const patchRetailer = async (retailerId, path, value) => {
  const url = `${API_ROOT}/retailers/${retailerId}`;

  return executePatchRequest(url, path, value);
};

export const createCredential = async (credential) => {
  const url = `${API_ROOT}/credentials`;

  return executePostRequest(url, credential);
};

export const getManualJobCredentials = async (allManualJobs) => {
  const url = `${API_ROOT}/manualUploads/GetManualJobCredentials?limit=0&allManualJobs=${allManualJobs}`;

  const credentials = await executeGetRequest(url, 'Username');

  return credentials ? credentials : [];
};

export const enqueueManualUploadByCredential = async (credentialId) => {
  const url = `${API_ROOT}/manualUploads/EnqueueManualUploadByCredential?credentialId=${credentialId}`;

  return executeGetRequest(url);
};

export const EnqueueMultipleManualUploadJobs = async (jobIds) => {
  const url = `${API_ROOT}/manualUploads/EnqueueMultipleManualUploadJobs`;

  return executePostRequest(url, jobIds);
};

export const enqueueManualUploadByJob = async (jobId) => {
  const url = `${API_ROOT}/manualUploads/EnqueueManualUploadByJob?jobId=${jobId}`;

  return executeGetRequest(url);
};

export const getCredentialsWithBadPasswords = async () => {
  const url = `${API_ROOT}/credentials?limit=0&ispasswordfresh=false&isactive=true`;
  const credentials = await executeGetRequest(url, 'Username');
  return credentials ? credentials.records : null;
};

export const getCredentials = async () => {
  const url = `${API_ROOT}/credentials?limit=0`;
  const credentials = await executeGetRequest(url, 'Username');

  return credentials ? credentials.records : null;
};

export const getCredentialsNotFreshPasswordsFirst = async () => {
  const url = `${API_ROOT}/credentials?limit=0&sort=ispasswordfresh&sortDirection=Ascending`;
  const credentials = await executeGetRequest(url, 'Username');

  return credentials ? credentials.records : [];
};

export const getCredentialsByChildClientId = async (childClientId) => {
  const url = `${API_ROOT}/credentials?limit=0&childClientId=${childClientId}&sort=IsPasswordFresh&sortdirection=Ascending`;
  const credentials = await executeGetRequest(url);

  return credentials ? credentials.records : [];
};

export const getCredentialById = async (credentialId) => {
  const url = `${API_ROOT}/credentials/${credentialId}`;

  return executeGetRequest(url);
};

export const getCredentialByJobId = async (jobId) => {
  const url = `${API_ROOT}/jobs/${jobId}/credential`;

  return executeGetRequest(url);
};

export const getRecentlyUsedCredentials = async (hoursBack) => {
  const url = `${API_ROOT}/credentials?hoursBack=${hoursBack}`;
  const credentials = executeGetRequest(url);

  return credentials || [];
};

export const updateCredential = async (credential) => {
  const url = `${API_ROOT}/credentials/${credential.id}`;

  return executePutRequest(url, credential);
};

export const deleteCredential = async (credentialId) => {
  const url = `${API_ROOT}/credentials/${credentialId}`;

  return executeDeleteRequest(url);
};

export const deleteMultipleCredentials = async (ids) => {
  const url = `${API_ROOT}/credentials/DeleteMultiple`;

  return executeDeleteRequest(url, ids);
};

export const patchCredential = async (credentialId, path, value) => {
  const url = `${API_ROOT}/credentials/${credentialId}`;

  return executePatchRequest(url, path, value);
};

export const getJobInterfaces = async (retailerType = 'NotSet') => {
  const retailer = retailerType == 'NotSet' ? '' : `?retailerType=${retailerType}`;
  const url = `${API_ROOT}/jobInterfaces${retailer}`;
  const jobInterfaces = await executeGetRequest(url);

  return jobInterfaces || [];
};

export const getJobInterfacesByServiceType = async (retailerServiceType = 'None') => {
  const serviceType = retailerServiceType == 'None' ? '' : `?retailerServiceType=${retailerServiceType}`;
  const url = `${API_ROOT}/jobInterfaces/GetByRetailerServiceType${serviceType}`;
  const jobInterfaces = await executeGetRequest(url);

  return jobInterfaces || [];
};

export const getAmazonRegionOptions = async () => {
  const url = `${API_ROOT}/childClients/getAmazonRegionOptions`;
  const options = await executeGetRequest(url);

  return options || [];
};

export const getAmazonSPAPIMarketplaceOptions = async () => {
  const url = `${API_ROOT}/credentials/getAmazonSPAPIMarketplaceOptions`;
  const options = await executeGetRequest(url);

  return options || [];
};

export const createJobTemplate = async (jobTemplate) => {
  const url = `${API_ROOT}/jobTemplates`;

  return executePostRequest(url, jobTemplate);
};

export const getJobTemplates = async () => {
  const url = `${API_ROOT}/jobTemplates?limit=0`;
  const jobTemplates = await executeGetRequest(url, 'Name');

  return jobTemplates ? jobTemplates.records : [];
};

export const getPaginatedJobTemplates = async (limit, offset, filters, sort, sortDirection) => {
  const url = `${API_ROOT}/jobTemplates/GetJobTemplates?limit=${limit}&offset=${offset}&${filters}&sort=${sort}&sortDirection=${sortDirection}`;
  const jobTemplates = await executeGetRequest(url, 'Name');
  return {
    data: jobTemplates.records,
    currentPage: jobTemplates.currentPage,
    totalPages: jobTemplates.totalPages,
    totalRecords: jobTemplates.totalRecords,
    totalFilteredRecords: jobTemplates.totalFilteredRecords,
  };
};

export const getJobTemplateById = async (jobTemplateId) => {
  const url = `${API_ROOT}/jobTemplates/${jobTemplateId}`;

  return executeGetRequest(url);
};

export const updateJobTemplate = async (jobTemplate) => {
  const url = `${API_ROOT}/jobTemplates/${jobTemplate.id}`;

  return executePutRequest(url, jobTemplate);
};

export const deleteJobTemplate = async (jobTemplateId) => {
  const url = `${API_ROOT}/jobTemplates/${jobTemplateId}`;

  return executeDeleteRequest(url);
};

export const deleteMultipleJobTemplates = async (ids) => {
  const url = `${API_ROOT}/jobTemplates/DeleteMultiple`;
  return executeDeleteRequest(url, ids);
};

export const patchJobTemplate = async (jobTemplateId, path, value) => {
  const url = `${API_ROOT}/jobTemplates/${jobTemplateId}`;

  return executePatchRequest(url, path, value);
};

export const createJob = async (job) => {
  const url = `${API_ROOT}/jobs`;

  return executePostRequest(url, job);
};

export const createJobsFromTemplates = async (bulkJobCreatePayload) => {
  const url = `${API_ROOT}/jobs/createFromTemplates`;

  return executePostRequest(url, bulkJobCreatePayload);
};

export const purchaseNewTwilioNumber = async (credentialId) => {
  const url = `${API_ROOT}/Twilio/PurchaseNewTwilioNumber?credentialId=${credentialId}`;

  return executePostRequest(url, null);
};

export const getJobs = async () => {
  const url = `${API_ROOT}/jobs?limit=0`;
  const jobs = executeGetRequest(url, 'Name');

  return jobs ? jobs.records : [];
};

export const getRunningJobs = async (limit, offset, filters, sort, sortDirection, excludeFilter) => {
  const url = `${API_ROOT}/jobs/getRunningJobs?limit=${limit}&offset=${offset}&${filters}&sort=${sort}&sortDirection=${sortDirection}&excludeFilter=${excludeFilter}`;
  const jobs = await executeGetRequest(url, 'Name');

  if (jobs && jobs.records) {
    const activeDataRunsCountsByJobId = await getActiveDataRunsCounts();
    const futureDataRunsCountsByJobId = await getFutureDataRunsCounts();

    jobs.records = jobs.records.map((job) => {
      let activeFutureCount = '';

      if (activeDataRunsCountsByJobId[job.id]) {
        activeFutureCount = `${activeDataRunsCountsByJobId[job.id]} active`;
      }

      if (futureDataRunsCountsByJobId[job.id]) {
        activeFutureCount += `${activeFutureCount ? ', ' : ''}${futureDataRunsCountsByJobId[job.id]} future`;
      }

      job.activeFutureCount = activeFutureCount;

      return job;
    });
  }
  return jobs ? jobs : [];
};

export const getManualJobsByCredentialId = async (credentialId) => {
  const url = `${API_ROOT}/manualUploads/getCurrentManualJobsWithTimestamp?credentialId=${credentialId}&limit=0`;

  const manualJobs = await executeGetRequest(url);

  return manualJobs ? manualJobs : [];
};

export const getStorageConnectionString = async () => {
  const url = `${API_ROOT}/manualUploads/getStorageConnectionString`;
  const storageConnectionString = await executeGetRequest(url, false);

  return storageConnectionString;
};

const getActiveDataRunsCounts = async () => {
  const url = `${API_ROOT}/dataruns/getbystate/active?limit=1&countBy=jobId`;
  const activeDataRuns = await executeGetRequest(url);

  return activeDataRuns ? activeDataRuns.countsByPropertyName : {};
};

const getFutureDataRunsCounts = async () => {
  const url = `${API_ROOT}/dataruns/getbystate/future?limit=1&countBy=jobId`;
  const futureDataRuns = await executeGetRequest(url);

  return futureDataRuns ? futureDataRuns.countsByPropertyName : {};
};

export const stopDataRun = async (dataRunId) => {
  const url = `${API_ROOT}/dataruns/${dataRunId}`;

  return executePatchRequest(url, '/status', 'stopped manually');
};

export const getLogEntriesByDataRunId = async (dataRunId) => {
  const url = `${API_ROOT}/logEntries/GetByDataRunId?dataRunId=${dataRunId}`;

  return executeGetRequest(url);
};

export const getRefreshTokenUrl = async (credentialId) => {
  const url = `${API_ROOT}/CredentialUpdateToken/getUrl?credentialId=${credentialId}`;

  return executeGetRequest(url);
};

export const getLoginStatus = async (credentialId) => {
  const url = `${API_ROOT}/credentials/CheckBotCredentialValidity?credentialId=${credentialId}`;

  return executeGetRequest(url);
};

export const createRefreshTokenURL = async (credentialId) => {
  const url = `${API_ROOT}/CredentialUpdateToken`;

  return executePostRequest(url, { credentialId: credentialId });
};

export const refreshTokenAndPassword = async (authToken, password, refreshToken) => {
  const url = `${API_ROOT}/CredentialUpdateToken/RefreshCredential?refreshToken=${refreshToken}&password=${encodeURIComponent(
    password
  )}&authToken=${authToken}`;

  return executeAnonymousRetailLinkBotPutRequest(url);
};

export const getRefreshToken = async (refreshToken) => {
  const url = `${API_ROOT}/CredentialUpdateToken/GetCredentialUpdateTokens?token=${refreshToken}`;

  return executeAnonymousGetRequest(url);
};

export const getJobsByCredential = async (credentialId) => {
  const url = `${API_ROOT}/jobs?limit=0&credentialId=${credentialId}`;
  const jobs = await executeGetRequest(url);

  return jobs ? jobs.records : [];
};

export const getSystemJobs = async () => {
  const url = `${API_ROOT}/jobs/SystemJobs?limit=0`;
  const systemJobs = await executeGetRequest(url);

  return systemJobs ? systemJobs.records : [];
};

export const getJobById = async (jobId) => {
  const url = `${API_ROOT}/jobs/${jobId}`;

  return executeGetRequest(url);
};

export const updateJob = async (job) => {
  const url = `${API_ROOT}/jobs/${job.id}`;

  return executePutRequest(url, job);
};

export const enqueueJobs = async () => {
  const accessToken = await getAccessToken();

  if (accessToken) {
    const url = `${API_ROOT}/jobs/enqueue`;
    const headers = new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    });

    return fetch(url, {
      method: 'PUT',
      headers,
      mode: 'cors',
    });
  }
};

export const deleteJob = async (jobId) => {
  const url = `${API_ROOT}/jobs/${jobId}`;

  return executeDeleteRequest(url);
};

export const toggleCredentialActivation = async (credential) => {
  const url = `${API_ROOT}/credentials/toggleActivation/${credential.id}`;

  return executePatchRequest(url, '/isActive', !credential.isActive);
};

export const deleteWalmartRunningJobsByJobId = async (jobId) => {
  const url = `${API_ROOT}/jobs/${jobId}/walmartrunningjobs`;

  return await executeDeleteRequest(url);
};

export const stopActiveAndFutureDataRunsByJobId = async (jobId) => {
  const url = `${API_ROOT}/jobs/${jobId}/activeandfuturedataruns`;

  return await executeDeleteRequest(url);
};

export const patchJob = async (jobId, path, value) => {
  const url = `${API_ROOT}/jobs/${jobId}`;

  return executePatchRequest(url, path, value);
};

export const createJobGroup = async (jobGroup) => {
  const url = `${API_ROOT}/jobGroups`;

  return executePostRequest(url, jobGroup);
};

export const getJobGroups = async (limit, offset, filters, sort, sortDirection) => {
  const url = `${API_ROOT}/jobGroups/GetJobGroups?limit=${limit}&offset=${offset}&${filters}&sort=${sort}&sortDirection=${sortDirection}`;
  const jobGroups = await executeGetRequest(url);

  return {
    data: jobGroups.records,
    currentPage: jobGroups.currentPage,
    totalPages: jobGroups.totalPages,
    totalRecords: jobGroups.totalRecords,
    totalFilteredRecords: jobGroups.totalFilteredRecords,
  };
};

export const getJobGroupsWithTemplates = async () => {
  const url = `${API_ROOT}/jobGroups?limit=0`;
  const jobGroups = await executeGetRequest(url, 'Name');

  return jobGroups ? jobGroups.records : [];
};

export const getJobGroupById = async (jobGroupId) => {
  const url = `${API_ROOT}/jobGroups/${jobGroupId}`;

  return executeGetRequest(url);
};

export const updateJobGroup = async (jobGroup) => {
  const url = `${API_ROOT}/jobGroups/${jobGroup.id}`;

  return executePutRequest(url, jobGroup);
};

export const deleteJobGroup = async (jobGroupId) => {
  const url = `${API_ROOT}/jobGroups/${jobGroupId}`;

  return executeDeleteRequest(url);
};

export const deleteMultipleJobGroups = async (ids) => {
  const url = `${API_ROOT}/jobGroups/DeleteMultiple`;

  return executeDeleteRequest(url, ids);
};

export const patchJobGroup = async (jobGroupId, path, value) => {
  const url = `${API_ROOT}/jobGroups/${jobGroupId}`;

  return executePatchRequest(url, path, value);
};

export const runJobGroupJobsByTemplate = async (jobGroupId, jobTemplateId) => {
  const url = `${API_ROOT}/jobGroups/${jobGroupId}/BulkRunJobsByJobTemplateId?jobtemplateid=${jobTemplateId}`;

  return executePutRequest(url);
};

export const getFutureDataRuns = async (limit, offset, filters, sort, sortDirection, excludeFilter) => {
  const url = `${API_ROOT}/dataruns/getbystate/future?limit=${limit}&offset=${offset}${filters}&sort=${sort}&sortDirection=${sortDirection}&excludeFilter=${excludeFilter}`;
  const futureDataRuns = await executeGetRequest(url);

  return futureDataRuns || [];
};

export const getActiveDataRuns = async (limit, offset, filters, sort, sortDirection, excludeFilter) => {
  const url = `${API_ROOT}/dataruns/getbystate/active?limit=${limit}&offset=${offset}${filters}&sort=${sort}&sortDirection=${sortDirection}&excludeFilter=${excludeFilter}`;
  const activeDataRuns = await executeGetRequest(url);

  return activeDataRuns || [];
};

export const deleteDataRun = async (id) => {
  const url = `${API_ROOT}/dataruns/${id}`;

  return executeDeleteRequest(url);
};

export const getDataRuns = async (startDate, endDate, limit, offset, filters, sort, sortDirection, excludeFilter) => {
  const sortCapitalized = sort.charAt(0).toUpperCase() + sort.slice(1);
  const url = `${API_ROOT}/DataRuns/GetByDates?startDate=${startDate}&endDate=${endDate}&limit=${limit}&offset=${offset}${filters}&sort=${sortCapitalized}&sortDirection=${sortDirection}&excludeFilter=${excludeFilter}`;
  const dataRuns = await executeGetRequest(url);

  return dataRuns || [];
};

export const getJobRuns = async (startDate, endDate, limit, offset, filters, sort, sortDirection, excludeFilter) => {
  const sortCapitalized = sort.charAt(0).toUpperCase() + sort.slice(1);
  const url = `${API_ROOT}/JobRuns/GetByDates?startDate=${startDate}&endDate=${endDate}&limit=${limit}&offset=${offset}${filters}&sort=${sortCapitalized}&sortDirection=${sortDirection}&excludeFilter=${excludeFilter}`;
  const jobRuns = await executeGetRequest(url);

  return jobRuns || [];
};

export const getAmazonRegionForCredential = async (credentialId) => {
  const url = `${API_ROOT}/Credentials/${credentialId}/GetSPAPIAmazonPortalAndRegion`;

  return executeAnonymousGetRequest(url);
};

export const generateAndSaveAmazonRefreshToken = async (credentialId, spApiOauthCode, redirectUri, amazonApiType) => {
  const url = `${API_ROOT}/Credentials/${credentialId}/GenerateAndSaveAmazonRefreshToken?spApiOauthCode=${spApiOauthCode}&redirectUri=${redirectUri}&amazonApiType=${amazonApiType}`;

  return executeAnonymousPutRequest(url);
};

export const getTheTradeDeskAuthenticationInfo = async (retailerServiceType) => {
  const url = `${API_ROOT}/Credentials/GetTheTradeDeskAuthenticationInfo?retailerServiceType=${retailerServiceType}`;

  return executeGetRequest(url);
};

export const foremanApiIsHealthy = async () => {
  const url = `${API_ROOT}/HealthCheck/ApiHealth`;
  const status = await executeGetRequest(url);

  return status.description === 'Healthy';
};

export const foremanDbIsHealthy = async () => {
  const url = `${API_ROOT}/HealthCheck/DbHealth`;
  const status = await executeGetRequest(url);

  return status.description === 'Healthy';
};

export const foremanHarvesterIsHealthy = async () => {
  const url = 'https://retailreporter-harvesters-prod.azurewebsites.net';

  return executeHealthCheckRequest(url);
};

export const lanternApiIsHealthy = async () => {
  const url = `${LANTERN_API_ROOT}/HealthCheck/ApiHealth`;
  const status = await executeGetRequest(url);

  return status.description === 'Healthy';
};

export const lanternDbIsHealthy = async () => {
  const url = `${LANTERN_API_ROOT}/HealthCheck/DbHealth`;
  const status = await executeGetRequest(url);

  return status.description === 'Healthy';
};

export const lanternHarvesterIsHealthy = async () => {
  const url = 'https://harvesters-prod.azurewebsites.net';

  return executeHealthCheckRequest(url);
};

export const SendRefreshEmail = async (credentialId) => {
  const url = `${API_ROOT}/CredentialUpdateToken/${credentialId}/SendRefreshTokenEmail`;

  return executePostRequest(url, credentialId);
};
export const getCredentialUsername = async (token) => {
  const url = `${API_ROOT}/CredentialUpdateToken/GetCredentialUsername?token=${token}`;

  return executeAnonymousGetRequest(url);
};

export const fetchGroupMembers = async ({ memberEmails = [] }) => {
  const accessToken = await getAccessToken();
  
  if (!accessToken) {
    return;
  }

  try {
    const response = await fetch('https://graph.microsoft.com/v1.0/groups/b5793a2d-815b-4d76-a963-3d0350e440a9/members', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new Error(`an error occurred while fetching group members: ${response.statusText}`);
    }

    const groupMembers = await response.json();

    if (memberEmails.length === 0) {
      const filteredMembers = groupMembers.value.map((member) => ({
        email: member.mail,
        displayName: member.displayName,
      }));

      return filteredMembers;
    }
    const members = groupMembers.value
      .filter((member) => memberEmails.includes(member.id))
      .map((member) => ({
        email: member.email,
        displayName: member.displayName,
      }));

    return members;
  } catch (error) {
    console.error('an error occurred while fetching group members', error); 
    throw error;
  }
};

export const deleteTaskGroup = async (taskGroupId) => {
  const accessToken = await getAccessToken();

  if (!accessToken) {
    return;
  }

  fetch(`${API_ROOT}/TaskGroups/delete/${taskGroupId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`an error occurred while deleting Task Group: ${response.statusText}`);
      }
      showSnackbar('success', 'Task Group deleted successfully');
    })
    .catch((error) => {
      showSnackbar('error', `Something went wrong while deleting Task Group: ${error.message}`);
    });
};

export const getTaskGroupById = async (taskGroupId) => {

  const accessToken = await getAccessToken();

  if (!accessToken) {
    return;
  }

  const response = await fetch(`${API_ROOT}/TaskGroups/GetTaskGroupById/${taskGroupId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return await response.json();
};

export const postTaskGroup = async (taskGroup) => {
  const accessToken = await getAccessToken();

  if (!accessToken) {
    return;
  }
  
  const response = await fetch(`${API_ROOT}/TaskGroups/PostTaskGroup`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(taskGroup),
  });

  return await response.json();
};

export const postTaskGroupAssignment = async ({ taskGroupAssignment, days }) => {
  const accessToken = await getAccessToken();

  if (!accessToken) {
    return;
  }

  const response = await fetch(`${API_ROOT}/TaskGroupAssignment/PostTaskGroupAssignment`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({ taskGroupAssignment, days }),
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return await response.json();
};

export const updateTaskGroup = async (taskGroup) => {
  const accessToken = await getAccessToken();

  if (!accessToken) {
    return;
  }

  const response = await fetch(`${API_ROOT}/TaskGroups/UpdateTaskGroup/${taskGroup.id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(taskGroup),
  });

  return response;
};
