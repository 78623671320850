import React, { useMemo, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Box, Checkbox } from '@mui/material';
import SearchBox from '../Searchbox/Searchbox';
import { styles } from './Table.styles';
import NoDataFound from '../../Assets/NoDataFound';
import Loader from '../Loader/Loader';
import { ConditionalColumn } from './ConditionaColumns';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SortIcon from '@mui/icons-material/Sort';

export function BasicTable({
  columns,
  setSearchValues,
  rowsPerPage,
  setRowsPerPage,
  page,
  setPage,
  count,
  data,
  actionButtons,
  noPagination,
  isLoading,
  isFullHeight,
  selected,
  setSelected,
  hasSelectionCheckboxes,
  openExtraInfoOnClick,
  hasMoreInfo,
  handleMoreInfoOnClick,
  setSelectedProperty,
  extraInfoParams,
  hasDuplicate,
  fromManualPage,
}) {
  const [sorting, setSorting] = useState({});

  const hasDifferentStatus = (status) => {
    return status != 'failed';
  };

  const handleSort = (columnId) => {
    setSorting((prevSorting) => ({
      ...prevSorting,
      [columnId]: prevSorting[columnId] === 'asc' ? 'desc' : 'asc',
    }));
  };

  const sortedItems = useMemo(() => {
    if (!Array.isArray(data)) {
      return [];
    }

    const sortedData = [...data];

    for (const columnId in sorting) {
      const sortOrder = sorting[columnId];

      sortedData.sort((a, b) => {
        const aValue = a[columnId];
        const bValue = b[columnId];

        if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
        if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;

        return 0;
      });
    }

    const sortedItems = sortedData.map((item) => ({
      ...item,
      actions: actionButtons && actionButtons(item),
    }));

    return sortedItems;
  }, [data, sorting, actionButtons]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = data.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };
  const isSelected = (id) => selected?.indexOf(id) !== -1;

  const handleSelectClick = (event, id) => {
    const selectedIndex = selected?.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const dateDifference = (startDate, lastDate) => {
    let difference = new Date(lastDate) - new Date(startDate);
    var milliseconds = Math.floor(difference % 1000);
    var seconds = Math.floor((difference / 1000) % 60);
    var minutes = Math.floor((difference / (1000 * 60)) % 60);
    var hours = Math.floor(difference / (1000 * 60 * 60));

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}:${milliseconds.toString().padStart(3, '0')}`;
  };

  const items = data && data?.map((item) => ({ ...item, actions: actionButtons && actionButtons(item) }));

  return (
    <>
      <TableContainer sx={isFullHeight ? styles.FullHeightTableContainer : styles.TableContainer}>
        <Table stickyHeader aria-label="sticky table" size={'small'} sx={{ tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow>
              {hasSelectionCheckboxes && (
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={selected?.length > 0 && selected?.length < data?.length}
                    checked={data?.length > 0 && selected?.length === data?.length}
                    onChange={handleSelectAllClick}
                    sx={{ mr: 2 }}
                  />
                </TableCell>
              )}
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ width: column.width }}>
                  <div onClick={() => handleSort(column.id)}>
                    {column.Header}
                    {column.id != 'actions' && column.id != 'isPasswordFresh' && (
                      <>
                        {sorting[column.id] ? (
                          <span>{sorting[column.id] === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}</span>
                        ) : (
                          <span>
                            <SortIcon fontSize="16" sx={{ ml: 1 }} />
                          </span>
                        )}
                      </>
                    )}
                  </div>
                  {column.id != 'actions' && column.id != 'isPasswordFresh' && (
                    <>
                      <Box>
                        <SearchBox
                          width={column.width}
                          key={`${column.header} SearchBox `}
                          listType={column.id}
                          setSearchValues={setSearchValues}
                          isRetailer={column.Header == 'Retailer'}
                        />
                      </Box>
                    </>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={columns.length}>
                  <Loader />
                </TableCell>
              </TableRow>
            ) : sortedItems?.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length}>
                  <NoDataFound />
                </TableCell>
              </TableRow>
            ) : (
              Array.isArray(sortedItems) &&
              sortedItems?.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {hasSelectionCheckboxes && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          onChange={(event) => handleSelectClick(event, row.id)}
                          disabled={fromManualPage && hasDifferentStatus(row.status)}
                        />
                      </TableCell>
                    )}
                    {columns.map((column) => {
                      const value = row[column.id];
                      const columnId = column.id;

                      const columnAlign = column.align;
                      return (
                        <ConditionalColumn
                          row={row}
                          columnId={columnId}
                          columnAlign={columnAlign}
                          columnHeader={column.Header}
                          value={value}
                          dateDifference={dateDifference}
                          openExtraInfoOnClick={openExtraInfoOnClick}
                          hasMoreInfo={hasMoreInfo}
                          setSelectedProperty={setSelectedProperty}
                          handleMoreInfoOnClick={handleMoreInfoOnClick}
                          extraInfoParams={extraInfoParams}
                          hasDuplicate={hasDuplicate}
                          fromManualPage={fromManualPage}
                        />
                      );
                    })}
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!noPagination && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={styles.tablePagination}
        />
      )}
    </>
  );
}
