import { CheckCircle, InfoOutlined, PlayArrow, RadioButtonUnchecked } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import EastIcon from '@mui/icons-material/East';
import { timelineItemClasses } from '@mui/lab';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  alpha,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { grey } from '@mui/material/colors';
import { useTheme } from '@mui/system';
import React, { useState } from 'react';
import CustomAvatarGroup from '../../Components/dacq/customAvatarGroup';
import { TaskGroupTableBase } from '../../Components/dacq/taskGroupTableBase';
import './claimButtonStyles.css';
import { stringAvatar, stringToColor } from './taskGroupUtils';

export const MemberTasks = () => {
  const [taskStatus, setTaskStatus] = useState('');
  const theme = useTheme();
  const initialTasks = [
    {
      id: 1,
      title: 'Task 1',
      members: ['Alex Smith', 'John Doe', 'Sarah Johnson', 'Jane Doe'],
      taskGroup: 'Group 1',
      startDate: new Date(),
      endDate: new Date(),
      subTasks: [
        {
          id: 1,
          title: 'Sub Task 1',
          isDone: false,
          description: 'lorem ipsum dolor sit amet',
          currentStatus: 'Completed',
          oldStatus: 'Ready',
        },
        {
          id: 2,
          title: 'Sub Task 2',
          isDone: false,
          description: 'lorem ipsum dolor sit amet',
          currentStatus: 'Failed',
          oldStatus: 'Failed',
        },
        {
          id: 3,
          title: 'Sub Task 3',
          isDone: false,
          description: 'lorem ipsum dolor sit amet',
          currentStatus: 'Ready',
          oldStatus: 'Ready',
        },
      ],
      description: 'lorem ipsum dolor sit amet',
    },
    {
      id: 2,
      title: 'Task 2',
      members: ['Alex Smith', 'John Doe', 'Sarah Johnson', 'Jane Doe'],
      taskGroup: 'Group 2',
      startDate: new Date(),
      endDate: new Date(),
      subTasks: [
        {
          id: 4,
          title: 'Sub Task 1',
          isDone: true,
          description: 'lorem ipsum dolor sit amet',
          currentStatus: 'Completed',
          oldStatus: 'Ready',
        },
        {
          id: 5,
          title: 'Sub Task 2',
          isDone: true,
          description: 'lorem ipsum dolor sit amet',
          currentStatus: 'Completed',
          oldStatus: 'Failed',
        },
        {
          id: 6,
          title: 'Sub Task 3',
          isDone: true,
          description: 'lorem ipsum dolor sit amet',
          currentStatus: 'Completed',
          oldStatus: 'Ready',
        },
      ],
      description: 'lorem ipsum dolor sit amet',
    },
    {
      id: 3,
      title: 'Task 3',
      members: ['Alex Smith', 'John Doe', 'Sarah Johnson', 'Jane Doe'],
      taskGroup: 'Group 3',
      startDate: new Date(),
      endDate: new Date(),
      subTasks: [
        {
          id: 7,
          title: 'Sub Task 1',
          isDone: true,
          description: 'lorem ipsum dolor sit amet',
          currentStatus: 'Completed',
          oldStatus: 'Ready',
        },
        {
          id: 8,
          title: 'Sub Task 2',
          isDone: true,
          description: 'lorem ipsum dolor sit amet',
          currentStatus: 'Completed',
          oldStatus: 'Failed',
        },
        {
          id: 9,
          title: 'Sub Task 3',
          isDone: true,
          description: 'lorem ipsum dolor sit amet',
          currentStatus: 'Completed',
          oldStatus: 'Ready',
        },
      ],
      description: 'lorem ipsum dolor sit amet',
    },
  ];
  const [currentTask, setCurrentTask] = useState(initialTasks[0]);
  const [tasks, setTasks] = useState(initialTasks);
  const handleStatusChange = (subTaskId, event) => {
    const newStatus = event.target.value;
    const newTasks = [...tasks];
    const taskIndex = newTasks.findIndex((task) => task.id === currentTask.id);
    const subTaskIndex = newTasks[taskIndex].subTasks.findIndex((subTask) => subTask.id === subTaskId);
    newTasks[taskIndex].subTasks[subTaskIndex].currentStatus = newStatus;
    setTasks(newTasks);
  };

  const memberTaskGroups = [
    { title: 'Group 1', members: ['Alex Smith', 'John Doe', 'Sarah Johnson', 'Jane Doe'] },
    { title: 'Group 2', members: ['Alex Smith', 'John Doe', 'Sarah Johnson', 'Jane Doe'] },
    { title: 'Group 3', members: ['Alex Smith', 'John Doe', 'Sarah Johnson', 'Jane Doe'] },
  ];

  const Stats = [
    { title: 'Total Claims', value: tasks.length, tooltip: 'Total number of running jobs' },
    { title: 'Processing', value: tasks.filter((task) => task.isActive).length },
    { title: 'Succeeded', value: tasks.filter((task) => !task.isActive).length },
    { title: 'Failed', value: 0 },
  ];

  const inactiveTasks = tasks.filter((task) => task.subTasks.every((subTask) => subTask.isDone));

  const tableBody = inactiveTasks.map((row, index) => ({
    name: row.title,
    taskGroup: row.taskGroup,
    members: <CustomAvatarGroup alignment="start" max={3} list={row.members} />,
    startDate: row.startDate.toLocaleString(),
    endDate: row.endDate.toLocaleString(),
  }));

  const claimNextTask = () => {
    const nextTaskIndex = tasks.findIndex((task) => !task.isActive);
    if (nextTaskIndex !== -1) {
      const newTasks = [...tasks];
      newTasks[nextTaskIndex].isActive = true;
      setTasks(newTasks);
    }
  };

  const handleToggle = (subTaskId) => {
    const newTasks = [...tasks];
    const taskIndex = newTasks.findIndex((task) => task.id === currentTask.id);
    const subTaskIndex = newTasks[taskIndex].subTasks.findIndex((subTask) => subTask.id === subTaskId);
    newTasks[taskIndex].subTasks[subTaskIndex].isDone = !newTasks[taskIndex].subTasks[subTaskIndex].isDone;
    setTasks(newTasks);
  };

  const handleChange = (event) => {
    setTaskStatus(event.target.value);
  };

  return (
    <Box display={'flex'} flexDirection={'column'} pr={16} pl={16} pt={8} pb={8} gap={6}>
      <Box className="d-flex justify-content-between" gap={8}>
        {Stats.map((stat, index) => (
          <Card
            key={index}
            sx={{
              borderRadius: '0.8rem',
              flex: 1,
            }}
          >
            <CardContent>
              <Box display={'flex'} gap={1}>
                <Typography mb={3} variant="subtitle1">
                  {stat.title}
                </Typography>
                {stat.tooltip && (
                  <Tooltip title={stat.tooltip}>
                    <InfoOutlined />
                  </Tooltip>
                )}
              </Box>
              <Typography variant="h4" fontWeight={600}>
                {stat.value}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Box>
      <Box display={'flex'} flexDirection={'column'} gap={6}>
        <Box display={'flex'} gap={6} bgcolor={theme.palette.taskGroupColors.greyBg} borderRadius={5} p={3}>
          <Box
            display={'flex'}
            gap={4}
            flexDirection={'column'}
            bgcolor={theme.palette.taskGroupColors.whiteBg}
            border={'1px solid transparent'}
            borderRadius={5}
            p={3}
            flex={2}
          >
            <Box display={'flex'} justifyContent={'space-between'}>
              <Typography variant="h5" fontWeight={600}>
                Current task
              </Typography>
              <button className="myBtn" onClick={claimNextTask}>
                Claim Next Task
                <div className="icon">
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                      <path
                        d="M1.05403 31.6175C0.271626 32.3972 0.271626 33.6614 1.05403 34.441C1.83644 35.2207 3.10497 35.2207 3.88737 34.441L1.05403 31.6175ZM35.5599 2.05152C35.5599 0.948871 34.6629 0.0549994 33.5564 0.0549994L15.5251 0.0549994C14.4187 0.0549994 13.5217 0.948871 13.5217 2.05152C13.5217 3.15416 14.4187 4.04804 15.5251 4.04804H31.5529V20.0202C31.5529 21.1228 32.4499 22.0167 33.5564 22.0167C34.6629 22.0167 35.5599 21.1228 35.5599 20.0202L35.5599 2.05152ZM3.88737 34.441L34.9731 3.46327L32.1397 0.639766L1.05403 31.6175L3.88737 34.441Z"
                        fill="#000"
                      />
                    </svg>
                  </span>
                </div>
              </button>
            </Box>
            <Box key={currentTask.id}>
              <Box display="flex" justifyContent="space-between" width="100%" mb={2}>
                <Typography variant="h6">{currentTask.title}</Typography>
                <CustomAvatarGroup list={currentTask.members} />
              </Box>
              <Timeline
                sx={{
                  [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                  },
                }}
              >
                {currentTask.subTasks.map((subTask) => (
                  <TimelineItem
                    key={subTask.id}
                    sx={{
                      [`& .${timelineItemClasses.root}:before`]: {
                        flex: 0,
                        padding: 0,
                      },
                    }}
                  >
                    <TimelineSeparator>
                      <TimelineDot
                        sx={{
                          boxShadow: 'none',
                          backgroundColor: 'transparent',
                        }}
                      >
                        {subTask.isDone ? (
                          <CheckCircle onClick={() => handleToggle(subTask.id)} color="success" />
                        ) : (
                          <RadioButtonUnchecked onClick={() => handleToggle(subTask.id)} sx={{ color: 'black' }} />
                        )}
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent variant="subtitle1" display={'flex'} alignItems={'center'}>
                      <Box display="flex" gap={1} alignItems="center" justifyContent="space-between" width="100%">
                        <Typography
                          sx={{
                            flex: 2,
                            textDecoration: subTask.isDone ? 'line-through' : 'none',
                            textTransform: 'none',
                          }}
                          variant="subtitle1"
                        >
                          {subTask.title}
                        </Typography>

                        <Box flex={3} display="flex" flexDirection={'column'} gap={1}>
                          <Box display="flex" gap={1} justifyContent={'end'} alignItems="center">
                            <Typography color={getTaskStatusColor(subTask.oldStatus, theme)} variant="subtitle1">
                              {subTask.oldStatus}
                            </Typography>

                            <EastIcon sx={{ color: getTaskStatusColor(subTask.oldStatus, theme) }} />
                            <FormControl sx={{ m: 1, minWidth: 120 }}>
                              <Select
                                sx={{
                                  backgroundColor: alpha(getTaskStatusColor(subTask.currentStatus, theme), 0.1),
                                  borderRadius: 99,
                                  fontWeight: 600,
                                  padding: '5px 15px',
                                  color: getTaskStatusColor(subTask.currentStatus, theme),
                                  width: '9rem',
                                }}
                                disableUnderline={true}
                                variant="standard"
                                value={subTask.currentStatus}
                                onChange={(e) => handleStatusChange(subTask.id, e)}
                                displayEmpty
                              >
                                <MenuItem value="Completed">Completed</MenuItem>
                                <MenuItem value="Failed">Failed</MenuItem>
                                <MenuItem value="Ready">Ready</MenuItem>
                                <MenuItem value="Blocked">Blocked</MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                          {subTask.currentStatus === 'Blocked' && (
                            <Box display="flex" gap={1} justifyItems={'end'} justifyContent={'end'}>
                              <TextField placeholder="Add a note..." id="standard-basic" variant="standard" />
                              <IconButton
                                sx={{
                                  backgroundColor: theme.palette.primary.main,
                                  borderRadius: '50%',
                                  color: 'white',
                                  fill: 'white',
                                }}
                                variant="contained"
                              >
                                <AddIcon />
                              </IconButton>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </TimelineContent>
                  </TimelineItem>
                ))}
              </Timeline>
              <Box display="flex" p={2} width={'100%'} justifyContent="flex-end">
                <Button
                  disabled={currentTask.subTasks.some((subTask) => !subTask.isDone)}
                  startIcon={<PlayArrow />}
                  variant="contained"
                >
                  Start Foreman process
                </Button>
              </Box>
            </Box>
          </Box>

          <Box bgcolor={theme.palette.taskGroupColors.greyBg} border={'1px solid transparent'} borderRadius={5} p={3} flex={1}>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Typography variant="h5" fontWeight={600} mb={2}>
                Task Group
              </Typography>
            </Box>
            <Box
              border={'1px solid'}
              bgcolor={theme.palette.background.paper}
              borderColor={grey[400]}
              borderRadius={5}
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
              p={3}
              justifyContent={'space-between'}
              mb={2}
            >
              {memberTaskGroups.map((taskGroup) => (
                <Box
                  key={taskGroup.title}
                  display={'flex'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  width={'100%'}
                  mb={2}
                >
                  <Typography variant="h6" fontWeight={600} mb={2}>
                    {taskGroup.title}
                  </Typography>
                  <Box display="flex" gap={2}>
                    <AvatarGroup sx={{ '&.MuiAvatarGroup-root': { justifyContent: 'start' } }}>
                      {taskGroup.members.map((member) => (
                        <Tooltip key={member} title={member}>
                          <Avatar {...stringAvatar(member)} sx={{ bgcolor: stringToColor(member) }} />
                        </Tooltip>
                      ))}
                    </AvatarGroup>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
        <Box
          bgcolor={theme.palette.taskGroupColors.greyBg}
          border={'1px solid'}
          borderColor={grey[400]}
          borderRadius={5}
          p={3}
          flex={1}
        >
          <Typography variant="h5" fontWeight={600} mb={2}>
            Completed Tasks
          </Typography>
          <TaskGroupTableBase fullWidth={true} isLoading={false} tableBody={tableBody} columns={doneTasksTableHeaders} />
        </Box>
      </Box>
    </Box>
  );
};

const getTaskStatusColor = (currentStatus, theme) => {
  switch (currentStatus) {
    case 'Completed':
      return theme.palette.taskGroupColors.success;
    case 'Failed':
      return theme.palette.taskGroupColors.red;
    case 'Ready':
      return theme.palette.taskGroupColors.darkGrey;
    case 'Blocked':
      return theme.palette.taskGroupColors.red;
    default:
      return theme.palette.taskGroupColors.darkGrey;
  }
};
export const doneTasksTableHeaders = ['Name', 'Task Group', 'Members', 'Start Date', 'End Date'];
