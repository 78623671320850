export const taskGroupHeaderStyles = {
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: '1rem',
    gap: '4rem',
    width: '100%',
  },

  dateText: {
    marginBottom: '0',
    whiteSpace: 'nowrap',
    display: 'flex',
    justifyContent: 'center',
    width: "15rem"
  },

  arrowBtn: {
    border: 'none',
    backgroundColor: 'transparent',
    borderRadius: '0.5rem',
    outline: 'none'
  },
  arrowBtnContainer: {
    display: 'flex',
  },
};
