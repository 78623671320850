import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Button, Tooltip } from '@mui/material';
import { isProductionEnvironment, ENVIRONMENT } from '../../../api-config';
import Logo from '../../Assets/Logo';
import useAuth from '../../../useAuthContext';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { AppBar, styles } from './ForemanNavBar.styles';
import UserMenu from '../UserMenu/UserMenu';
import NotificationMenu from '../NotificationMenu/NotificationMenu';
import { useState } from 'react';
import UndoIcon from '@mui/icons-material/Undo';
import { NavLink } from 'react-router-dom';
import { CredentialsContext } from '../../Context/CredentialsWithBadPasswordContext';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useContext } from 'react';
import useIsForemanDeveloper from '../../../../src/custom-hooks/userIsForemanDeveloper';

export default function ForemanNavbar(props) {
  const { user } = useAuth();
  const { isDrawerOpen, handleOpenDrawer } = props;
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const isUserMenuOpen = Boolean(menuAnchorEl);
  const [notificationsAnchorEl, setNotificationsAnchorEl] = useState(null);
  const isNotificationMenuOpen = Boolean(notificationsAnchorEl);
  const userIsForemanDeveloper = useIsForemanDeveloper();

  const { credentialsWithBadPassword } = useContext(CredentialsContext);

  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleNotificationClick = (event) => {
    setNotificationsAnchorEl(event.currentTarget);
  };
  const handleNotificationClose = () => {
    setNotificationsAnchorEl(null);
  };

  return (
    <AppBar position="fixed" open={isDrawerOpen} sx={isProductionEnvironment() ? styles.appBar : styles.appBarSecondary}>
      <Toolbar>
        <IconButton
          id="DrawerMenu"
          color="inherit"
          aria-label="open drawer"
          onClick={handleOpenDrawer}
          edge="start"
          sx={{
            ...styles.drawerIconButton,
            ...(isDrawerOpen && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>
        {!isDrawerOpen && (
          <>
            <Box sx={{ m: 1.5 }}>
              <Logo />
            </Box>
            <Typography variant="h6" noWrap component="div" sx={styles.title}>
              Foreman{isProductionEnvironment() ? '' : ` ${ENVIRONMENT}`}
            </Typography>
          </>
        )}
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: styles.mainBox }}>
          <Box>
            <Tooltip title="Open Notifications">
              <IconButton
                className="NotifBtn"
                id="notificationButton"
                size="large"
                aria-label="show new notifications"
                color="inherit"
                onClick={handleNotificationClick}
                sx={styles.notificationIconButton}
              >
                <Badge badgeContent={credentialsWithBadPassword?.length} color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            </Tooltip>
          </Box>
          <Box sx={{ pt: 1 }}>
            {user?.avatar ? (
              <img src={user?.avatar} alt="avatar" style={styles.avatarImage} />
            ) : (
              <AccountCircleIcon fontSize="medium" />
            )}
            <IconButton
              id="Avatar"
              size="small"
              edge="end"
              aria-label="account of current user"
              aria-haspopup="true"
              color="inherit"
              sx={styles.profileIconButton}
              onClick={handleMenuClick}
            >
              <Typography> {user?.displayName} </Typography>
              <KeyboardArrowDownIcon />
            </IconButton>
          </Box>
        </Box>
      </Toolbar>
      <UserMenu handleMenuClose={handleMenuClose} menuAnchorEl={menuAnchorEl} isUserMenuOpen={isUserMenuOpen} />

      <NotificationMenu
        isOpen={isNotificationMenuOpen}
        handleClose={handleNotificationClose}
        notificationsAnchorEl={notificationsAnchorEl}
      />
    </AppBar>
  );
}
