import React, { useEffect } from 'react';
import { Grid, Typography, FormControl, MenuItem, TextField, Chip, Box, OutlinedInput } from '@mui/material';
import Select from '@mui/material/Select';
import { styles } from '../Credentials.styles';
import AccordionComponent from '../../../Components/AdditionalPropertiesForm/AccordionComponent';
import AmazonProfilesAndAdvertisersCleanUp from './AmazonProfilesAndAdvertisersCleanUp';

export const AmazonRetailer = ({
  selectedRetailerServiceType,
  modifiedCredential,
  setModifiedCredential,
  AMAZON_SELLING_PARTNER_API_AUTH_URL,
  credential,
  amazonAccountTypeOptions,
  selectedAmazonAccountType,
  selectedAmazonSPAPIMarketplaces,
  amazonSPAPIMarketplaceOptionsForRegion,
  AMAZON_ADVERTISING_API_AUTH_URL,
  setSelectedAmazonSPAPIMarketplaces,
  setSelectedAmazonAccountType,
  amazonSPAPIMarketplaceOptions,
  setAmazonSPAPIMarketplaceOptionsForRegion,
  childClient,
}) => {
  useEffect(() => {
    if (modifiedCredential.retailerServiceType == 3) {
      setSelectedAmazonAccountType(
        amazonAccountTypeOptions.find((option) => option.value == modifiedCredential.parameterValues?.AmazonAccountType)
      );

      setSelectedAmazonSPAPIMarketplaces(
        amazonSPAPIMarketplaceOptions.filter((option) =>
          modifiedCredential.parameterValues?.MarketplaceIds?.split(',').includes(option.value)
        )
      );

      setAmazonSPAPIMarketplaceOptionsForRegion(
        amazonSPAPIMarketplaceOptions.filter((marketplace) => {
          return marketplace.group === childClient.value?.amazonRegionDescription;
        })
      );
    }
  }, [modifiedCredential.retailerServiceType]);

  const amazonAccountTypeSelectOnChange = (option) => {
    let accountType = amazonAccountTypeOptions.find((account) => account.value === option);
    setSelectedAmazonAccountType(accountType);

    setModifiedCredential({
      ...modifiedCredential,
      parameterValues: { ...modifiedCredential.parameterValues, AmazonAccountType: option },
    });
  };

  const isValidAmazonMarketplace = (option) => {
    return option.value !== 0;
  };

  const amazonMarketplacesSelectOnChange = (element) => {
    const removeDuplicates = (array) => array.filter((value, index) => array.indexOf(value) === array.lastIndexOf(value));

    let marketPlaces = element.map((marketPlace) => {
      if (!marketPlace.value) {
        return amazonSPAPIMarketplaceOptionsForRegion.find((option) => option.value === marketPlace);
      } else return marketPlace;
    });

    marketPlaces = removeDuplicates(marketPlaces);

    let value = '';

    if (marketPlaces != null) {
      value = Array.from(marketPlaces, (option) => option.value).toString();
    }

    setSelectedAmazonSPAPIMarketplaces(marketPlaces);

    setModifiedCredential({
      ...modifiedCredential,
      parameterValues: { ...modifiedCredential.parameterValues, MarketplaceIds: value },
    });
  };

  const parseItems = (items) => {
    if (Array.isArray(items)) return items;
    try {
      return JSON.parse(items);
    } catch (e) {
      console.error('Invalid JSON string:', items);
      return [];
    }
  };

  return (
    <>
      {selectedRetailerServiceType.value === 2 && (
        <>
          <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
            <Grid item sm={2}>
              <Typography>Merchant Id</Typography>
            </Grid>
            <Grid item sm={10}>
              <FormControl fullWidth>
                <TextField
                  type="text"
                  autoComplete="off"
                  value={
                    (modifiedCredential && modifiedCredential.parameterValues && modifiedCredential.parameterValues.MerchantId) ||
                    ''
                  }
                  onChange={(e) =>
                    setModifiedCredential({
                      ...modifiedCredential,
                      parameterValues: { ...modifiedCredential.parameterValues, MerchantId: e.target.value },
                    })
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
            <Grid item sm={2}>
              <Typography>Marketplace Id</Typography>
            </Grid>
            <Grid item sm={10}>
              <FormControl fullWidth>
                <TextField
                  type="text"
                  autoComplete="off"
                  value={
                    (modifiedCredential &&
                      modifiedCredential.parameterValues &&
                      modifiedCredential.parameterValues.MarketPlaceId) ||
                    ''
                  }
                  onChange={(e) =>
                    setModifiedCredential({
                      ...modifiedCredential,
                      parameterValues: { ...modifiedCredential.parameterValues, MarketPlaceId: e.target.value },
                    })
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
            <Grid item sm={2}>
              <Typography>Paid Id</Typography>
            </Grid>
            <Grid item sm={10}>
              <FormControl fullWidth>
                <TextField
                  type="text"
                  autoComplete="off"
                  value={
                    (modifiedCredential && modifiedCredential.parameterValues && modifiedCredential.parameterValues.PaidId) || ''
                  }
                  onChange={(e) =>
                    setModifiedCredential({
                      ...modifiedCredential,
                      parameterValues: { ...modifiedCredential.parameterValues, PaidId: e.target.value },
                    })
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        </>
      )}
      {selectedRetailerServiceType.value === 4 && (
        <>
          <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
            <Grid item sm={2}>
              <Typography>Amazon Account Id</Typography>
            </Grid>
            <Grid item sm={10}>
              <FormControl fullWidth>
                <TextField
                  type="text"
                  autoComplete="off"
                  value={
                    (modifiedCredential && modifiedCredential.parameterValues && modifiedCredential.parameterValues.AccountId) ||
                    ''
                  }
                  onChange={(e) =>
                    setModifiedCredential({
                      ...modifiedCredential,
                      parameterValues: { ...modifiedCredential.parameterValues, AccountId: e.target.value },
                    })
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
            <Grid item sm={2}>
              <Typography>Amazon Vendor Id</Typography>
            </Grid>
            <Grid item sm={10}>
              <FormControl fullWidth>
                <TextField
                  type="text"
                  autoComplete="off"
                  value={
                    (modifiedCredential && modifiedCredential.parameterValues && modifiedCredential.parameterValues.VendorId) ||
                    ''
                  }
                  onChange={(e) =>
                    setModifiedCredential({
                      ...modifiedCredential,
                      parameterValues: { ...modifiedCredential.parameterValues, VendorId: e.target.value },
                    })
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        </>
      )}
      {selectedRetailerServiceType.value === 19 && (
        <>
          <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
            <Grid item sm={2}>
              <Typography>Marketplace Id</Typography>
            </Grid>
            <Grid item sm={10}>
              <FormControl fullWidth>
                <TextField
                  type="text"
                  autoComplete="off"
                  value={
                    (modifiedCredential &&
                      modifiedCredential.parameterValues &&
                      modifiedCredential.parameterValues.MarketplaceId) ||
                    ''
                  }
                  onChange={(e) =>
                    setModifiedCredential({
                      ...modifiedCredential,
                      parameterValues: { ...modifiedCredential.parameterValues, MarketplaceId: e.target.value },
                    })
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
            <Grid item sm={2}>
              <Typography>Data upload AWS account ID</Typography>
            </Grid>
            <Grid item sm={10}>
              <FormControl fullWidth>
                <TextField
                  type="text"
                  autoComplete="off"
                  value={
                    (modifiedCredential &&
                      modifiedCredential.parameterValues &&
                      modifiedCredential.parameterValues.DataUploadId) ||
                    ''
                  }
                  onChange={(e) =>
                    setModifiedCredential({
                      ...modifiedCredential,
                      parameterValues: { ...modifiedCredential.parameterValues, DataUploadId: e.target.value },
                    })
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
            <Grid item sm={2}>
              <Typography>Instance Id</Typography>
            </Grid>
            <Grid item sm={10}>
              <FormControl fullWidth>
                <TextField
                  type="text"
                  autoComplete="off"
                  value={
                    (modifiedCredential && modifiedCredential.parameterValues && modifiedCredential.parameterValues.InstanceId) ||
                    ''
                  }
                  onChange={(e) =>
                    setModifiedCredential({
                      ...modifiedCredential,
                      parameterValues: { ...modifiedCredential.parameterValues, InstanceId: e.target.value },
                    })
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container alignItems="center" sx={{ marginTop: '1rem' }} key={'KVLocation'}>
            <Grid item sm={2}>
              <Typography>KVLocation</Typography>
            </Grid>
            <Grid item sm={10}>
              <FormControl fullWidth>
                <TextField
                  type="text"
                  autoComplete="off"
                  value={
                    (modifiedCredential && modifiedCredential.parameterValues && modifiedCredential.parameterValues.KVLocation) ||
                    ''
                  }
                  onChange={(e) =>
                    setModifiedCredential({
                      ...modifiedCredential,
                      parameterValues: { ...modifiedCredential.parameterValues, KVLocation: e.target.value },
                    })
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        </>
      )}
      {selectedRetailerServiceType.value === 3 && (
        <>
          <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
            <Grid item sm={2}>
              <Typography>Merchant Id</Typography>
            </Grid>
            <Grid item sm={10}>
              <FormControl fullWidth>
                <TextField
                  type="text"
                  autoComplete="off"
                  value={
                    (modifiedCredential && modifiedCredential.parameterValues && modifiedCredential.parameterValues.MerchantId) ||
                    ''
                  }
                  onChange={(e) =>
                    setModifiedCredential({
                      ...modifiedCredential,
                      parameterValues: { ...modifiedCredential.parameterValues, MerchantId: e.target.value },
                    })
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
            <Grid item sm={2}>
              <Typography>Authorize:</Typography>
            </Grid>
            <Grid item sm={10}>
              <FormControl fullWidth>
                <Typography>
                  {AMAZON_SELLING_PARTNER_API_AUTH_URL}
                  {credential.id}
                </Typography>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
            <Grid item sm={2}>
              <Typography>Amazon Account Type</Typography>
            </Grid>
            <Grid item sm={10}>
              <FormControl fullWidth>
                <Select
                  value={selectedAmazonAccountType?.value || null}
                  onChange={(e) => amazonAccountTypeSelectOnChange(e.target.value)}
                >
                  {amazonAccountTypeOptions.map((accountType) => (
                    <MenuItem key={accountType.value} value={accountType.value}>
                      {accountType.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
            <Grid item sm={2}>
              <Typography>Amazon Marketplaces</Typography>
            </Grid>
            <Grid item sm={10}>
              <FormControl fullWidth>
                <Select
                  multiple
                  value={selectedAmazonSPAPIMarketplaces || []}
                  onChange={(e) => amazonMarketplacesSelectOnChange(e.target.value)}
                  input={<OutlinedInput id="select-multiple-chip" sx={styles.Inputs} />}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value.label} />
                      ))}
                    </Box>
                  )}
                >
                  {amazonSPAPIMarketplaceOptionsForRegion.filter(isValidAmazonMarketplace).map((marketplace) => (
                    <MenuItem key={marketplace.value} value={marketplace.value}>
                      {marketplace.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </>
      )}
      {selectedRetailerServiceType.value === 1 && (
        <>
          <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
            <Grid item sm={2}>
              <Typography>Authorize:</Typography>
            </Grid>
            <Grid item sm={10}>
              <FormControl fullWidth>
                <Typography>
                  {AMAZON_ADVERTISING_API_AUTH_URL}
                  {credential.id}
                </Typography>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
            <Grid item sm={2}>
              <Typography>Profiles</Typography>
            </Grid>
            <Grid item sm={10}>
              <FormControl fullWidth>
                <TextField
                  type="text"
                  autoComplete="off"
                  value={
                    (modifiedCredential && modifiedCredential.parameterValues && modifiedCredential.parameterValues.Profiles) ||
                    ''
                  }
                  onChange={(e) =>
                    setModifiedCredential({
                      ...modifiedCredential,
                      parameterValues: { ...modifiedCredential.parameterValues, Profiles: e.target.value },
                    })
                  }
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
            <Grid item sm={2}></Grid>
            <Grid item sm={10}>
              {credential && credential.parameterValues && credential.parameterValues.Profiles && (
                <AccordionComponent
                  AccordionTitle="Clean up profiles"
                  AccordionContent={
                    <AmazonProfilesAndAdvertisersCleanUp
                      data={parseItems(credential?.parameterValues?.Profiles)}
                      isProfileComponent={true}
                      modifiedCredential={modifiedCredential}
                      setModifiedCredential={setModifiedCredential}
                    />
                  }
                />
              )}
            </Grid>
          </Grid>

          <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
            <Grid item sm={2}>
              <Typography>Advertisers</Typography>
            </Grid>
            <Grid item sm={10}>
              <FormControl fullWidth>
                <TextField
                  type="text"
                  autoComplete="off"
                  value={
                    (modifiedCredential &&
                      modifiedCredential.parameterValues &&
                      modifiedCredential.parameterValues.Advertisers) ||
                    ''
                  }
                  onChange={(e) =>
                    setModifiedCredential({
                      ...modifiedCredential,
                      parameterValues: { ...modifiedCredential.parameterValues, Advertisers: e.target.value },
                    })
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
            <Grid item sm={2}></Grid>
            <Grid item sm={10}>
              {credential && credential.parameterValues && credential.parameterValues.Advertisers && (
                <AccordionComponent
                  AccordionTitle="Clean up Advertisers"
                  AccordionContent={
                    <AmazonProfilesAndAdvertisersCleanUp
                      data={parseItems(credential?.parameterValues?.Advertisers)}
                      isProfileComponent={false}
                      modifiedCredential={modifiedCredential}
                      setModifiedCredential={setModifiedCredential}
                    />
                  }
                />
              )}
            </Grid>
          </Grid>
        </>
      )}
      {selectedRetailerServiceType.value !== 1 && selectedRetailerServiceType.value !== 19 && (
        <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
          <Grid item sm={2}>
            <Typography>Amazon OTP Secret Key</Typography>
          </Grid>
          <Grid item sm={10}>
            <FormControl fullWidth>
              <TextField
                type="text"
                autoComplete="off"
                value={
                  (modifiedCredential && modifiedCredential.parameterValues && modifiedCredential.parameterValues.OtpSecretKey) ||
                  ''
                }
                onChange={(e) =>
                  setModifiedCredential({
                    ...modifiedCredential,
                    parameterValues: { ...modifiedCredential.parameterValues, OtpSecretKey: e.target.value },
                  })
                }
              />
            </FormControl>
          </Grid>
        </Grid>
      )}
    </>
  );
};
