import React from 'react';
import BasicConfirmationModal from './ConfirmationModal';
import { Grid, Checkbox } from '@mui/material';
import RetailerIcon from '../Icons/RetailerIcon';

export default function DeleteMultipleModal({
  modalType,
  warningMessage,
  setShowModal,
  showModal,
  confirmedDeleteAction,
  referenceText,
  isMultipleDelete,
  selectedItems,
  selected,
  setSelected,
  handleDeleteMultiple,
}) {
  const handleSelectClick = (event, id) => {
    const selectedIndex = selected?.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  return (
    <>
      <BasicConfirmationModal
        modalHeader={<>Delete {`${modalType}`}</>}
        modalBody={
          <>
            <Grid container>
              <Grid item xs={11} align="left">
                <p>{`Are you sure you want to delete the following ${modalType} : `}</p>
                <br />
                {selectedItems.map((item) => (
                  <>
                    <Grid container sx={{ alignItems: 'baseline' }}>
                      <Grid xs={1}>
                        <Checkbox
                          checked={selected.filter((selectedItem) => selectedItem === item.id)}
                          onChange={(event) => handleSelectClick(event, item.id)}
                          indeterminate
                        />
                      </Grid>
                      <Grid xs={1}>
                        <RetailerIcon retailerType={item.retailerType} retailerName={item.retailerName} />
                      </Grid>
                      <Grid xs={9}>
                        <p>
                          {item.id} - {item?.name ? item?.name : item?.username}
                        </p>
                      </Grid>
                    </Grid>
                    <hr />
                  </>
                ))}
              </Grid>
            </Grid>
          </>
        }
        warningMessage={warningMessage}
        setShowModal={setShowModal}
        showModal={showModal}
        referenceText={referenceText}
        confirmedAction={confirmedDeleteAction}
        isDelete={true}
        isMultipleDelete={isMultipleDelete}
        handleDeleteMultiple={handleDeleteMultiple}
      />
    </>
  );
}
