import React from 'react';
import WalmartIcon from './WalmartIcon';
import TargetIcon from '../Icons/TargetIcon';
import SamsClubIcon from '../Icons/SamsClubIcon';
import AmazonIcon from '../Icons/AmazonIcon';
import KrogerIcon from '../Icons/KrogerIcon';
import SystemIcon from '../Icons/SystemIcon';
import CostcoIcon from './CostcoIcon';
import AlbertsonsIcon from './AlberstonsIcon';
import InstacartIcon from './InstacartIcon';
import AdsChannelsIcon from './AdsChannelsIcon';

const RetailerIcon = ({ retailerType, retailerName }) => {
  return (
    <>
      {((typeof retailerName === 'string' && retailerName.includes('Walmart')) ||
        retailerType == 1 ||
        retailerType == 6 ||
        retailerType == 7) && <WalmartIcon />}
        {((typeof retailerName === 'string' && retailerName.includes('Ads Channels')) ||
        retailerType == 9) && <AdsChannelsIcon />}
      {(retailerName == 'Target' || retailerType == 3) && <TargetIcon />}
      {(retailerName == "Sam's Club" || retailerType == 5) && <SamsClubIcon />}
      {(retailerName == 'Amazon' || retailerType == 4) && <AmazonIcon />}
      {(retailerName == 'Kroger' || retailerType == 2) && <KrogerIcon />}
      {(retailerName == 'Costco' || retailerType == 8) && <CostcoIcon />}
      {(retailerName == 'Not Set' || retailerType == 99) && <SystemIcon />}
      {(retailerName == 'Albertsons' || retailerType == 10) && <AlbertsonsIcon />}
      {(retailerName == 'Instacart' || retailerType == 12) && <InstacartIcon />}
    </>
  );
};

export default RetailerIcon;
