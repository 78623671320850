import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, Box, Skeleton, CardActionArea, Typography, CircularProgress } from '@mui/material';
import { API_ROOT } from '../../../api-config';
import { fetchGroupMembers, getTaskGroupById } from '../../../Utilities/ForemanApiClient';
import { ActionType } from './taskGroupUtils';
import TaskGroupWeeklyViewHeader from '../../Components/dacq/taskGroupsWeeklyViewHeader';
import AddTaskGroupModal from '../../Components/dacq/add-taskgroup-modal/addTaskGroupModal';
import dayjs from 'dayjs';
import { useFetch } from '../../hooks/useFetch';
import { taskGroupWeeklyViewStyles } from './taskGroupWeeklyViewStyles';
import { getCurrentWeekDates, getWeekdayFromDate } from '../../Utils/Utilities';
import { CapacityChip } from '../../Components/dacq/capacityChip';
import CustomAvatarGroup from '../../Components/dacq/customAvatarGroup';
import { ErrorComponent } from '../../Components/dacq/errorComponent';

function TaskGroupsWeeklyView() {
  const [initialTaskGroupAssignmentsData, setInitialTaskGroupAssignmentsData] = useState([]);
  const [isTaskGroupMembersFetching, setIsTaskGroupMembersFetching] = useState(false);
  const [membersList, setMembersList] = useState({});
  const [filteredTaskGroupAssignmentsData, setFilteredTaskGroupAssignmentsData] = useState([]);
  const [open, setOpen] = useState(false);
  const [actionType, setActionType] = useState(ActionType.ASSIGNMENTEDIT);
  const [selectedTaskGroup, setSelectedTaskGroup] = useState({});
  const [isLoadingMoreTaskAssignments, setIsLoadingMoreTaskAssignments] = useState(false);
  const navigate = useNavigate();

  const { data, isLoading, error } = useFetch({
    url: `${API_ROOT}/taskgroupassignment/GetTaskGroupAssignments?weekStartDate=${dayjs().startOf('week').add(1, 'day')}`,
    method: 'GET',
  });

  useEffect(async () => {
    if (data) {
      setIsTaskGroupMembersFetching(true);
      const groupmembersList = await fetchGroupMembers({
        memberEmails: data.map((group) => group.memberEmails).flat(),
      });

      setIsTaskGroupMembersFetching(false);
      setMembersList(groupmembersList);
      setInitialTaskGroupAssignmentsData(data);
      setFilteredTaskGroupAssignmentsData(data);
    }
  }, [data]);

  const handleFilterChange = (filterOption) => {
    if (filterOption['lowCapacity']) {
      const filteredTaskGroupAssignmentsData = initialTaskGroupAssignmentsData.filter(
        (item) => item.memberEmails.length < item.minimumRequiredMembers
      );
      setFilteredTaskGroupAssignmentsData(filteredTaskGroupAssignmentsData);
    } else {
      setFilteredTaskGroupAssignmentsData(initialTaskGroupAssignmentsData);
    }
  };

  if (error) {
    return <ErrorComponent />;
  }

  return (
    
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        gap: '3rem',
      }}
    >
      <TaskGroupWeeklyViewHeader
        onFilterChanged={handleFilterChange}
        onWeekChanged={async (newDate) => {
          setIsLoadingMoreTaskAssignments(true);
          const token = localStorage.getItem('auth');
          const response = await fetch(`${API_ROOT}/taskgroupassignment/GetTaskGroupAssignments?weekStartDate=${newDate}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          });

          if (response.ok) {
            const data = await response.json();
            setInitialTaskGroupAssignmentsData(data);
            setFilteredTaskGroupAssignmentsData(data);
            setIsLoadingMoreTaskAssignments(false);
          } else {
            console.error('Error fetching task group assignments:', response.statusText);
            setIsLoadingMoreTaskAssignments(false);
          }
        }}
      />

      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', gap: '2rem',px:"2rem",}}>
        {isLoading || isTaskGroupMembersFetching
          ? Array.from({ length: 5 }, (_, i) => (
              <Skeleton key={i} sx={{ borderRadius: '1.3rem' }} variant="rectangular" width={300} height={800} />
            ))
          : getCurrentWeekDates().map((day, index) => {
              const formattedDay = dayjs(day).format('YYYY-MM-DD');
              const dayData = filteredTaskGroupAssignmentsData.filter((item) => {
                const taskDate = new Date(item.scheduledDate);
                return taskDate.getDay() === index + 1;
              });
              return (
                <div
                  onClick={() => {
                    navigate('details/' + formattedDay);
                  }}
                  key={index}
                  style={taskGroupWeeklyViewStyles.dayCardWrapper}
                >
                  <h6 style={{ margin: 0, fontWeight: 600, marginLeft: '1rem' }}>{getWeekdayFromDate(new Date(day))}</h6>
                  <div style={taskGroupWeeklyViewStyles.cardsWrapper}>
                    {dayData.length > 0 ? (
                      dayData.map((taskGroup, groupIndex) => {
                        const actualCapacity = taskGroup.memberEmails.length;
                        return (
                          <div key={groupIndex}>
                            <Card
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                              sx={{
                                minWidth: 320,
                                borderRadius: '0.8rem',
                                border: '1px solid transparent',
                              }}
                            >
                              <CardActionArea
                                onClick={async () => {
                                  try {
                                    const responseData = await getTaskGroupById(taskGroup.taskGroupId);
                                    const taskGroupArgs = {
                                      id: responseData.id,
                                      name: responseData.name,
                                      description: responseData.description,
                                      minimumRequiredMembers: responseData.minimumRequiredMembers,
                                      memberEmails: taskGroup.memberEmails,
                                      schedule: responseData.schedule,
                                      isActive: responseData.isActive,
                                      createdAt: responseData.createdAt,
                                    };
                                    setActionType(ActionType.ASSIGNMENTEDIT);
                                    setSelectedTaskGroup(taskGroupArgs);
                                    setOpen(true);
                                  } catch (error) {
                                    console.error('Error fetching task group:', error);
                                  }
                                }}
                              >
                                <CardContent>
                                  <Typography gutterBottom variant="h5" component="div">
                                    {taskGroup.taskGroupName}
                                  </Typography>
                                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <CapacityChip value={actualCapacity} minimumValue={taskGroup.minimumRequiredMembers} />
                                    <CustomAvatarGroup
                                      max={3}
                                      list={membersList
                                        .filter((member) => taskGroup.memberEmails.includes(member.id))
                                        .map((member) => member.displayName)}
                                    />
                                  </Box>
                                </CardContent>
                              </CardActionArea>
                            </Card>
                          </div>
                        );
                      })
                    ) : (
                      <Typography variant="body1" component="div" style={{ padding: '0.5rem' }}>
                        No task group scheduled for this day!
                      </Typography>
                    )}
                  </div>
                </div>
              );
            })}
        {isLoadingMoreTaskAssignments && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.05)',
            }}
          >
            <Box sx={{ display: 'flex' }}>
              <CircularProgress />
            </Box>
          </div>
        )}
        <AddTaskGroupModal
          handleClose={() => setOpen(false)}
          open={open}
          argsActionType={actionType}
          taskGroup={{ ...selectedTaskGroup }}
          onTaskGroupAdd={(task) => {
            const taskAssignment = initialTaskGroupAssignmentsData.find((item) => item.taskGroupId === task.id);
            taskAssignment.memberEmails = task.memberEmails;
          }}
        />
      </Box>
    </Box>
  );
}

export default TaskGroupsWeeklyView;
